// import React, { useState, useEffect } from 'react';
// import { useNavigate,Link } from 'react-router-dom';
// import axios from 'axios';
// import config from '../config';
// import SucessMessage from './shared/SucessMessage';
// function ProductsList() {
//   const [products, setProductsData] = useState([]);
//   const navigate = useNavigate();
//   const [showSuccessMessage, setShowSuccessMessage] = useState([]);
//   const gotoAddProducts = () => {
//     navigate('/admin/addproducts');
//   };
//   const onClaim = () => {
//     setShowSuccessMessage(true); 
// setTimeout(() => {
//   setShowSuccessMessage(false); 
// },3000)

//   };
//   useEffect(() => {
//     // Fetch data from the API


//     axios.post(`${config.backendUrl}/admin/products`,{}, {
//       headers: {
//         'token': localStorage.getItem('token'), // Example authorization header
//         'Content-Type': 'application/json', // Example content type header
//         // Add more headers as needed
//       }
//       })
//   .then(response => {
//     // Handle response
//     console.log(response);
//     setProductsData(response.data.data)
//   })
//   .catch(error => {
//     // Handle error
//     console.error('Error:', error);
//   });


//   }, []); // Empty dependency array means this effect runs only once on component mount

//   return (
//     <div>
//     {/* {showSuccessMessage &&  <SucessMessage value={"Rewards Granted Succesfully"} />} */}

//         <div className="flex justify-between items-center"> 
//       <div> 
//       <h2>Products</h2>
//       <div className="breadcrumbs">
//         {" "}
//         <Link to="/admin/dashboard">Home</Link>
//         <span>{">"} </span>Add Products{" "}
//       </div>
//       </div>

//       <div>
//         <button  onClick={gotoAddProducts}  className='genreport-btn'>Add Products</button>
//       </div>
//       </div>

//         <div className="product-list">
//             { products ?  (
//               products.map(product => (
//                 <div className='product-listitem' key={product.id}>
//                     <figure className="productimage">
//                         <img src={product.imageUrl} alt="description" />
//                         {/* <figcaption>{product.name}</figcaption> */}
//                         <div className="prodcuttitle">
//                         <div className="prodcutccode"><span>{product.model_name}</span></div>
//                         <h4><a>{product.name}</a></h4>
//                         </div>  
//                     </figure>
//                 </div>
//             ))) : (<div>Loading Data</div>)}
//         </div>
//     </div>
//   );
// }
// export default ProductsList;







import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import SucessMessage from './shared/SucessMessage';

function ProductsList() {
  const [products, setProductsData] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]); // Track selected product IDs
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false); // Show confirmation popup
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [editProductName, setEditProductName] = useState('');
  const [editModelName, setEditModelName] = useState('');
  const navigate = useNavigate();

  const gotoAddProducts = () => {
    navigate('/admin/addproducts');
  };

  const onClaim = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await axios.delete(`${config.backendUrl}/admin/products/${productId}`, {
        headers: {
          token: localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      // Remove the deleted product from the list
      setProductsData(products.filter((product) => product.id !== productId));
      setShowDeletePopup(false);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleEditClick = (product) => {
    setSelectedProductId(product.id);
    setEditProductName(product.name);
    setEditModelName(product.model_name);
    setShowEditPopup(true); // Open the edit popup
  };

  const handleEditSubmit = async () => {
    try {
      const updatedProduct = {
        id:selectedProductId,
        name: editProductName,
      };

      await axios.put(
        `${config.backendUrl}/admin/product/name`,
        updatedProduct,
        {
          headers: {
            token: localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );

      setProductsData((prevState) =>
        prevState.map((product) =>
          product.id === selectedProductId
            ? { ...product, name: editProductName, model_name: editModelName }
            : product
        )
      );

      setShowEditPopup(false); // Close the edit popup
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const handleCheckboxChange = (productId) => {
    // If the product is already selected, remove it, otherwise add it
    setSelectedProductIds((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId) // Deselect if already selected
        : [...prevSelected, productId] // Select the product
    );
  };

  const handleRemoveSelectedProducts = () => {
    setShowDeletePopup(true); // Show confirmation popup before removal
  };

  const handleConfirmDelete = async () => {
    try {
        // Send a single API call with an array of selected product IDs
        await axios.delete(
            `${config.backendUrl}/admin/products`,
            {
                data: { ids: selectedProductIds }, // Send `ids` array in the `data` key
                headers: {
                    token: localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
            }
        );

        // Remove deleted products from the list
        setProductsData((prevState) =>
            prevState.filter((product) => !selectedProductIds.includes(product.id))
        );

        // Clear selected products
        setSelectedProductIds([]);
        setShowSuccessMessage(true);
        setTimeout(() => {
            setShowSuccessMessage(false);
        }, 3000);
        setShowDeletePopup(false); // Close the confirmation popup
    } catch (error) {
        console.error('Error removing products:', error);
    }
};


  useEffect(() => {
    axios
      .post(
        `${config.backendUrl}/admin/products`,
        {},
        {
          headers: {
            token: localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        setProductsData(response.data.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <h2>Products</h2>
          <div className="breadcrumbs">
            <Link to="/admin/dashboard">Home</Link>
            <span>{'>'} </span>Add Products{' '}
          </div>
        </div>
        <div>
          <button onClick={gotoAddProducts} className="genreport-btn">
            Add Products
          </button>
          {/* Remove Selected Products */}
          {selectedProductIds.length > 0 && (
            <div className="remove-products-btn my-2 genreport-btn">
              <button onClick={handleRemoveSelectedProducts}>
                Remove Products
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="product-list">
        {products ? (
          products.map((product) => (
            <div
              className="product-listitem relative group"
              key={product.id}
              style={{ position: 'relative' }}
            >
              <figure className="productimage">
                <img src={product.imageUrl} alt="description" />
                <div className="prodcuttitle">
                  <div className="prodcutccode">
                    <span>{product.model_name}</span>
                  </div>
                  <h4>
                    <a>{product.name}</a>
                  </h4>
                  {/* Edit Icon */}
                  <div
                    className="edit-icon absolute top-2 right-2 hidden group-hover:block"
                    style={{
                      cursor: 'pointer',
                      zIndex: 10,
                      color: 'blue',
                      background: 'white',
                      borderRadius: '50%',
                      padding: '5px',
                    }}
                    onClick={() => handleEditClick(product)} // Open edit modal with product details
                  >
                    ✏️
                  </div>
                </div>
              </figure>

              {/* Checkbox to select products */}
              <div
                className={`checkbox-icon absolute top-2 left-2 ${selectedProductIds.includes(product.id) ? 'block' : 'hidden'
                  } group-hover:block`}
                style={{
                  cursor: 'pointer',
                  zIndex: 10,
                }}
              >
                <input
                  type="checkbox"
                  checked={selectedProductIds.includes(product.id)}
                  onChange={() => handleCheckboxChange(product.id)} // Handle checkbox change
                  style={{ width: '20px', height: '20px' }}
                />
              </div>
            </div>
          ))
        ) : (
          <div>Loading Data</div>
        )}
      </div>

      {/* Confirmation Popup for Deleting Selected Products */}
      {showDeletePopup && (
        <div
          className="popup-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 100 }}
        >
          <div className="popup-content bg-white p-5 rounded shadow-lg">
            <h3>Are you sure you want to remove the selected products?</h3>
            <div className="mt-4 flex justify-end gap-3">
              <button
                className="btn-cancel bg-gray-300 px-4 py-2 rounded"
                onClick={() => setShowDeletePopup(false)}
              >
                Cancel
              </button>
              <button
                className="btn-delete bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleConfirmDelete}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Product Modal */}
      {showEditPopup && (
        <div
          className="popup-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 100 }}
        >
          <div className="popup-content bg-white p-5 rounded shadow-lg" style={{ width: '500px' }}>
            <h3>Edit Product</h3>
            <div className="py-2">
              <h4>Product Name:</h4>
              <input
                type="text"
                value={editProductName}
                onChange={(e) => setEditProductName(e.target.value)}
                className="input-field mt-1"
              />
            </div>
            {/* <div className="py-2">
              <h4>Model Name:</h4>
              <input
                type="text"
                value={editModelName}
                onChange={(e) => setEditModelName(e.target.value)}
                className="input-field mt-1"
              />
            </div> */}
            <div className="popup-actions space-x-3 mt-3">
              <button
                className="btn-cancel bg-gray-300 px-4 py-2 rounded"
                onClick={() => setShowEditPopup(false)}
              >
                Cancel
              </button>
              <button
                className="btn-save bg-green-500 text-white px-4 py-2 rounded"
                onClick={handleEditSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductsList;

