// import React, { useState,useEffect } from "react";
// import {useNavigate,  Link } from "react-router-dom";
// import axios from "axios";
// import { ModelList } from "../common/constants";
// import { CiEdit } from "react-icons/ci";

// function Models() {
 
 
//   const navigate = useNavigate();
 
//   const [modelsData,setModels] = useState([]);
//   useEffect(() => {
//     const fetchData = async () => {
//       const response = await  axios.post(ModelList,{ }, {
//       headers: {
//         'token': localStorage.getItem('token'), // Example authorization header
//         'Content-Type': 'application/json', // Example content type header
//         // Add more headers as needed
//       }
//       })
//     .then(result => {
//       // Handle response
//       setModels(result.data.data);
    
//     })
//     .catch(error => {
//       // Handle error
//       console.error('Error:', error);
//     });
    
//     };
//     fetchData();
//   }, []); // No dependencies, this runs only on mount and unmount

  
 
  

  




//   return (
//     <div>
//       <div className="flex justify-between items-center"> 
//       <div> 
//       <h2>Plumbers</h2>
//       <div className="breadcrumbs">
//         {" "}
//         <Link to="/admin/dashboard">Home</Link>
//         <span>{">"} </span>Models{" "}
//       </div>
//       </div>
      
//       <div>
//       <Link to="/admin/add-model" > <button    className='genreport-btn'>Add Model</button></Link>
//       </div>
//       </div>
//       <div className="whitebox">
//         <div>
//           <table className="tablegrid" width={"100%"}>
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>Name</th>
              
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {modelsData.map((item, index) => (
//                 <tr key={index}>
//                   <td>{index+1}</td>
//                   <td className="redfont">{item.model}</td>
                 
//                   <td><CiEdit /></td>
           
//                 </tr>
//               ))}
//             </tbody>
//           </table>

     
//         </div>
//       </div>


  
//     </div>
//   );
// }

// export default Models;


import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { ModelList } from "../common/constants";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri"; // Import delete icon
import config from "../config";

function Models() {
  const navigate = useNavigate();

  const [modelsData, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null); // Track selected model for edit
  const [editModelName, setEditModelName] = useState("");
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [modelToDelete, setModelToDelete] = useState(null); // Track model to delete

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post(ModelList, {}, {
        headers: {
          'token': localStorage.getItem('token'), // Example authorization header
          'Content-Type': 'application/json', // Example content type header
        }
      })
        .then(result => {
          setModels(result.data.data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };
    fetchData();
  }, []);

  // Handle model editing
  const handleEditClick = (model) => {
    setSelectedModel(model);
    setEditModelName(model.model);
    setShowEditPopup(true); // Show edit popup
  };

  // Handle model update
  const handleEditSubmit = async () => {
    try {
      const updatedModel = { name: editModelName, id: selectedModel?.id };

      await axios.put(`${config.backendUrl}/admin/modal/name`, updatedModel, {
        headers: {
          'token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        }
      });

      setModels(modelsData.map((model) =>
        model.id === selectedModel.id ? { ...model, model: editModelName } : model
      ));

      setShowEditPopup(false); // Close the edit popup
    } catch (error) {
      console.error('Error updating model:', error);
    }
  };

  // Handle delete click
  const handleDeleteClick = (model) => {
    setModelToDelete(model);
    setShowDeletePopup(true); // Show delete confirmation popup
  };

  // Confirm model deletion
  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`${ModelList}`, {
        data: { id: modelToDelete?.id },
        headers: {
          'token': localStorage.getItem('token'),
          'Content-Type': 'application/json',
        }
      });

      setModels(modelsData.filter((model) => model.id !== modelToDelete.id)); // Remove deleted model
      setShowDeletePopup(false); // Close the delete popup
    } catch (error) {
      console.error('Error deleting model:', error);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <h2>Models</h2>
          <div className="breadcrumbs">
            <Link to="/admin/dashboard">Home</Link>
            <span>{'>'} </span>Models
          </div>
        </div>
        <div>
          <Link to="/admin/add-model">
            <button className="genreport-btn">Add Model</button>
          </Link>
        </div>
      </div>

      <div className="whitebox">
        <div>
          <table className="tablegrid" width={"100%"}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {modelsData.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td className="redfont">{item.model}</td>
                  <td className="flex space-x-2">
                    {/* Edit icon */}
                    <CiEdit
                      onClick={() => handleEditClick(item)}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        marginRight: "10px",
                        fontSize:"20px"
                      }}
                    />
                    {/* Delete icon */}
                    <RiDeleteBin6Line
                      onClick={() => handleDeleteClick(item)}
                      style={{
                        cursor: "pointer",
                        color: "red",
                        fontSize:"20px"
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Edit Model Popup */}
      {showEditPopup && (
        <div
          className="popup-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 100 }}
        >
          <div className="popup-content bg-white p-5 rounded shadow-lg" style={{ width: "500px" }}>
            <h3>Edit Model</h3>
            <div>
              <label>Model Name</label>
              <input
                type="text"
                value={editModelName}
                onChange={(e) => setEditModelName(e.target.value)}
                className="input-field mt-2"
              />
            </div>
            <div className="mt-4 flex justify-end gap-3">
              <button
                className="btn-cancel bg-gray-300 px-4 py-2 rounded"
                onClick={() => setShowEditPopup(false)}
              >
                Cancel
              </button>
              <button
                className="btn-save bg-green-500 text-white px-4 py-2 rounded"
                onClick={handleEditSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Popup */}
      {showDeletePopup && (
        <div
          className="popup-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 100 }}
        >
          <div className="popup-content bg-white p-5 rounded shadow-lg">
            <h3>Are you sure you want to delete this model?</h3>
            <p className="py-2">Deleting this model will also remove all associated products from the system.</p>
            <div className="mt-4 flex justify-end gap-3">
              <button
                className="btn-cancel bg-gray-300 px-4 py-2 rounded"
                onClick={() => setShowDeletePopup(false)}
              >
                Cancel
              </button>
              <button
                className="btn-delete bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleDeleteConfirm}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Models;
