import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import { UserProtectedRoute } from "./UserProtectedRoute";

import React from 'react';
import Productslist from '../admin/Productslist';
import AddProducts from '../admin/AddProducts';
import Customers from '../admin/Customers';
import Plumber from '../admin/Plumber';
import QrList from '../admin/QrList';
import QrGenerate from '../admin/QrGenerate';
import Reports from '../admin/Reports';
import PersonalDetails from '../user/PersonalDetails';
import Rewords from '../user/Rewords';
import RewardPoints from '../user/RewardPoints';
import RewardsHistory from '../user/RewardsHistory';
import ClaimRewards from '../user/ClaimRewards';
import ScanQr from '../user/ScanQr';

import Login from '../admin/Login';

import Dashboard from "../admin/Dashboard";
import UserLogin from "../user/UserLogin";
import UserRegistration from "../user/UserRegistration";
import { getData } from "../common/storage";
import EditProduct from "../admin/EditProduct";
import QrGeneratedList from "../admin/QrGeneratedList";
import PrintQr from "../admin/PrintQr";
import Models from "../admin/Models";
import AddModel from "../admin/AddModel";


// import ClaimRewards from '../user/ClaimRewards';

const Routes = () => {
  const { token } = useAuth();
 
  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/service",
      element: <div>Service Page</div>,
    },
    {
      path: "/about-us",
      element: <div>About Us</div>,
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/admin",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/admin/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/admin/products",
          element: <Productslist />,
        },
        {
          path: "/admin/addproducts",
          element: <AddProducts />
        },
        {
          path: "/admin/product/edit",
          element: <EditProduct />
        },
        {
          path: "/admin/models",
          element: <Models />
        },
        {
          path:"/admin/add-model",
          element:<AddModel />
        },
        {
          path:"/admin/edit-model/:model_id",
          element:<AddModel />
        },
        {
          path: "/admin/plumbers",
          element: <Plumber />
        },
        {
          path: "/admin/customers",
          element: <Customers />
        },
        {
          path: "/admin/qrlist",
          element: <QrList />
        },
        {
          path: "/admin/qrgenerate",
          element: <QrGenerate />
        },
        {
          path: "/admin/generated-qrs",
          element: <QrGeneratedList />
        },
        {
          path: "/admin/print-qrs/:qr_list_id",
          element: <PrintQr />
        },
        {
          path: "/admin/reports",
          element: <Reports />
        },
        {
          path: "*",
          element: <Navigate to="/admin/dashboard" replace />,
        },
        {
          path: "",
          element: <Navigate to="/admin/dashboard" replace />,
        },
      ],
    },
    { path: "*", element: <Navigate to="/admin/dashboard" replace /> },
    {
      path: "/user",
      element: <UserProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/user/personaldetails",
          element: <PersonalDetails />,
        },
        {
          path: "/user/rewards",
          element: <Rewords />,
        },
        {
          path: "/user/points",
          element: <RewardPoints />,
        },
        {
          path: "/user/rewardshistory",
          element: <RewardsHistory />,
        },
        {
          path: "/user/claimrewards",
          element: <ClaimRewards />,
        },
        {
          path: "/user/scanqr",
          element: <ScanQr />,
        },
        {
          path: "*",
          element: <Navigate to="/user/rewards" />,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/admin/login",
      element: <Login/>,
    },
    {
      path: "/user/login",
      element: getData("userToken") ? <Navigate to="/user/rewards" replace /> : <UserLogin />,
    },
    {
      path: "/user/register",
      element: <UserRegistration />,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;


// import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
// import { useAuth } from "../provider/authProvider";
// import { ProtectedRoute } from "./ProtectedRoute";
// import { UserProtectedRoute } from "./UserProtectedRoute";

// import React from 'react';
// import Productslist from '../admin/Productslist';
// import AddProducts from '../admin/AddProducts';
// import Customers from '../admin/Customers';
// import Plumber from '../admin/Plumber';
// import QrList from '../admin/QrList';
// import QrGenerate from '../admin/QrGenerate';
// import Reports from '../admin/Reports';
// import PersonalDetails from '../user/PersonalDetails';
// import Rewords from '../user/Rewords';
// import RewardPoints from '../user/RewardPoints';
// import RewardsHistory from '../user/RewardsHistory';
// import ClaimRewards from '../user/ClaimRewards';
// import ScanQr from '../user/ScanQr';
// import Login from '../admin/Login';
// import Dashboard from "../admin/Dashboard";
// import UserLogin from "../user/UserLogin";
// import UserRegistration from "../user/UserRegistration";
// import { getData } from "../common/storage";
// import EditProduct from "../admin/EditProduct";
// import QrGeneratedList from "../admin/QrGeneratedList";
// import PrintQr from "../admin/PrintQr";
// import Models from "../admin/Models";
// import AddModel from "../admin/AddModel";

// const Routes = () => {
//   const { token } = useAuth();
//   const userToken = getData("userToken");

//   const commonAdminRoutes = [
//     { path: "dashboard", element: <Dashboard /> },
//     { path: "products", element: <Productslist /> },
//     { path: "addproducts", element: <AddProducts /> },
//     { path: "product/edit", element: <EditProduct /> },
//     { path: "models", element: <Models /> },
//     { path: "add-model", element: <AddModel /> },
//     { path: "edit-model/:model_id", element: <AddModel /> },
//     { path: "plumbers", element: <Plumber /> },
//     { path: "customers", element: <Customers /> },
//     { path: "qrlist", element: <QrList /> },
//     { path: "qrgenerate", element: <QrGenerate /> },
//     { path: "generated-qrs", element: <QrGeneratedList /> },
//     { path: "print-qrs/:qr_list_id", element: <PrintQr /> },
//     { path: "reports", element: <Reports /> },
//   ];

//   const commonUserRoutes = [
//     { path: "personaldetails", element: <PersonalDetails /> },
//     { path: "rewards", element: <Rewords /> },
//     { path: "points", element: <RewardPoints /> },
//     { path: "rewardshistory", element: <RewardsHistory /> },
//     { path: "claimrewards", element: <ClaimRewards /> },
//     { path: "scanqr", element: <ScanQr /> },
//   ];

//   const routes = createBrowserRouter([
//     // Public routes
//     // { path: "/service", element: <div>Service Page</div> },
//     // { path: "/about-us", element: <div>About Us</div> },
    
//     // Authenticated-only routes
//     ...(token ? [
//       {
//         path: "/admin",
//         element: <ProtectedRoute />,
//         children: [
//           ...commonAdminRoutes,
//           { path: "*", element: <Navigate to="/admin/dashboard" replace /> },
//         ],
//       },
//       {
//         path: "/user",
//         element: <UserProtectedRoute />,
//         children: [
//           ...commonUserRoutes,
//           { path: "*", element: <Navigate to="/user/rewards" replace /> },
//         ],
//       }
//     ] : [
//       // Non-authenticated routes
//       { path: "/admin/login", element: <Login /> },
//       { path: "/user/login", element:  getData("userToken") ? <Navigate to="/user/rewards" replace /> : <UserLogin /> },
//       { path: "/user/register", element: <UserRegistration /> },
//     ]),

//     // Fallback for unmatched routes
//     // { path: "*", element: <Navigate to={token ? "/admin/dashboard" : "/user/login"} replace /> },
//   ]);

//   return <RouterProvider router={routes} />;
// };

// export default Routes;
