import React,{useState,useEffect} from 'react'
import {useNavigate, Link } from "react-router-dom";
import Popover from '../admin/shared/Popover';
import Preview from './shared/Preview';
import axios from "axios";
import config from "../config";
import { USER_REWARD_POINTS } from "../common/constants";
import Pagination  from "../common/Pagination";

function Customers() {
  const [selectedPopoverId, setSelectedPopoverId] = useState(null);
  const [customers, setCustomersData] = useState([]);
  const [rewardPoints, setRewardPoints] = useState([]);
  const [selectedCustomer,setCustomerName] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 10; // Number of items per page

  useEffect(() => {
    // Fetch data from the API
  
    axios.post(`${config.backendUrl}/admin/customers`,{  params: {
      page: currentPage,
    
    },}, {
      headers: {
        'token': localStorage.getItem('token'), // Example authorization header
        'Content-Type': 'application/json', // Example content type header
        // Add more headers as needed
      }
      })
    .then(response => {
      // Handle response
      setCustomersData(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / limit));
    })
    .catch(error => {
      // Handle error
      console.error('Error:', error);
    });
  
  
  }, [currentPage, limit]); // Empty dependency array means this effect runs only once on component mount
  const navigate = useNavigate();



  
  const gotoReports = () => {
    navigate('/admin/reports');
};

const handleOpenPopover = (userId,name) => {
  const data = {
    user_id: userId,
 
  };

  setCustomerName({user_name:name,user_id:userId});
  axios.post(USER_REWARD_POINTS,data, {
    headers: {
      'token': localStorage.getItem('token'), // Example authorization header
      'Content-Type': 'application/json', // Example content type header
      // Add more headers as needed
    }
    })
  .then(response => {
    // Handle response
    setRewardPoints(response.data.data);
  
 
   setSelectedPopoverId(userId);
   
  })
  .catch(error => {
    // Handle error
    console.error('Error:', error);
  });
};
const handlePageChange = (newPage) => {
  
  setCurrentPage(newPage);
};
const handleClosePopover = () => {
  setSelectedPopoverId(null);
};
  return (
    <div>
    <div className="flex justify-between items-center"> 
    <div> 
    <h2>Customers</h2>
    <div className="breadcrumbs">
      {" "}
      <Link to="/admin/dashboard">Home</Link>
      <span>{">"} </span>Customers{" "}
    </div>
    </div>
    
    <div>
    <button  onClick={gotoReports}  className='genreport-btn'>Generate Report</button>
    </div>
    </div>
    <div className="whitebox">
      <div>
        <table className="tablegrid" width={"100%"}>
          <thead>
            <tr>
              <th>SNo</th>
              <th>Name</th>
              <th>Location</th>
              <th>Joined Date</th>
              <th>Accumulated Points </th>
              <th>Points Redeemed </th>
              <th>Mobile Number</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
          {customers.map((item, index) => (
              <tr key={index}>
              <td>{index + 1 + (currentPage - 1) * limit}</td>
              <td onClick={() => handleOpenPopover(item.id,item.name)} className="redfont">{item.name}</td>
              <td>{(item.location) ? item.location : "-"}</td>
              <td>{item.joined_dt}</td>
              <td>{item.Accumulated_points}</td>
              <td>{item.points_redeemed}</td>
              <td>{item.mobile}</td>
              <td>{(item.email) ? item.email : "-"}</td>
            </tr>
              ))}
          </tbody>
        </table>

        <div className="flex justify-between">
          <div> </div>
          <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
             />

        </div>
      </div>
    </div>

    {selectedPopoverId && (
          <Popover onClose={handleClosePopover} isVisible={selectedPopoverId !== null}>
            <Preview reward_points={rewardPoints} popOverClose={handleClosePopover} userData={selectedCustomer}> </Preview>
          </Popover>
        )}
  </div>
  )
}

export default Customers